Header {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 1rem;
  height: 6.25rem;
  background: linear-gradient(rgba(0, 0, 0, 0.4), transparent);

  .logo {
    width: calc(1rem + 2vw);

    &:hover {
      filter: brightness(0.8) drop-shadow(0 0 2px black);
    }
  }

  .languageButtons {
    .btn {
      img {
        width: 3vw;
        height: 2vw;
      }
    }

    .divider {
      background-color: #fff;
      border-radius: 50%;
      overflow: hidden;
      width: 2px;
    }
  }

  .socialLink {
    padding: 0.2rem;

    button {
      font-weight: 600 !important;
      color: #072d50 !important;
      background: transparent;
      border: none;

      &:hover {
        filter: brightness(200%);
      }
    }
  }

  &.scrolled {
    opacity: 0.7; // Adjust this value as needed
  }
}