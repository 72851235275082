.water-particle {
  position: absolute;
  pointer-events: none;
  z-index: 0; // Ensure it's behind other content
  filter: drop-shadow(0 0 4px white);
  animation: rainbowRipple 3s infinite;
}

@keyframes rainbowRipple {
  0% {
    background: rgba(255, 0, 0, 0.9);
    transform: scale(0.5);
  }
  16.67% {
    background: rgba(255, 165, 0, 0.9);
  }
  33.34% {
    background: rgba(0, 128, 0, 0.9);
  }
  50.01% {
    background: rgba(0, 0, 255, 0.9);
  }
  66.68% {
    background: rgba(75, 0, 130, 0.9);
  }
  83.35% {
    background: rgba(238, 130, 238, 0.9);
  }
  100% {
    background: rgba(255, 255, 0, 0.9);
    transform: scale(1.5);
  }
}
