@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body, html {
    background-color: #f5f5f5;
    color: #0c0c0c;
    font-family: 'Inter', Arial, sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

#root {
    overflow: hidden;
    white-space: pre-line;

    * {
        z-index: 1;
        transition: 0.2s;
    }
}

p {
    font-size: calc(0.4vw + 0.7rem) !important;
    font-weight: 200 !important;
    line-height: calc(0.4vw + 0.95rem) !important;
    margin-bottom: 0 !important;
    max-width: 95vw;
}

h1 {
    font-size: calc(0.6vw + 1rem) !important;
    font-weight: 800 !important;
    max-width: 95vw;
    margin-bottom: 0;
}

h2 {
    font-size: calc(0.6vw + 0.9rem) !important;
    font-weight: 600 !important;
    max-width: 95vw;
    margin-bottom: 0;
}

h3 {
    font-size: calc(0.6vw + 0.8rem) !important;
    font-weight: 600 !important;
    max-width: 95vw;
    margin-bottom: 0;
}

h4 {
    font-size: calc(0.6vw + 0.7rem) !important;
    font-weight: 600 !important;
    max-width: 95vw;
    margin-bottom: 0;
}

i {
    font-size: 1.5rem;
    filter: drop-shadow(0 0 0.05rem #072d50);
    color: #072d50; /* Lila Farbakzent */
    &:hover {
        filter: brightness(150%);
    }
}

a {
    font-size: calc(0.6vw + 0.5rem) !important;
    font-weight: 600 !important;
    line-height: 1.15 !important;
    margin-bottom: 0 !important;
    &:hover {
        filter: brightness(125%);
    }
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
    width: 1vh;
}

/* Track */
::-webkit-scrollbar-track {
    background: #3d3d3d;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(100, 100, 100);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0e0e0e;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: rgb(255, 255, 255);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: rgb(0, 0, 0);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}