#Home {
  .namePresent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    width: 100vw;
    height: calc(100vh + 6.25rem);

    h1 {
      font-size: calc(8vw + 2rem) !important;
      background: linear-gradient(90deg, #072d50, #3a506b, #4e8382, #072d50); // Cool, sophisticated colors
      background-size: 400%; // Increased to allow more space for the gradient to move
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: colorShift 12s ease-in-out infinite;

      @keyframes colorShift {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 50% 0%;
        }
        100% {
          background-position: 100% 50%;
        }
      }
    }

    a {
      margin: 1rem;
      :hover {
        filter: drop-shadow(0 0 0.2rem #072d50);
      }
      button {
        color: #bbbbbb !important;
        text-decoration: none;
        font-weight: 300 !important;
      }
    }
  }

  .projectsPresent {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    width: 100vw;
    min-height: 40rem;
    padding: 0.5rem;

    .cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;

      gap: 0.5rem;

      .card {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15rem;
        text-decoration: none;
        transform: scale(0.95);

        &:hover {
          transform: scale(1);
          filter: none;
        }

        .card-img-top {
          height: 12rem;
          width: auto;
          max-width: 12rem;
          margin: 1.5rem;
          filter: drop-shadow(0 0 2px black);
        }
      }
    }
  }

  .skillsPresent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 1rem;
    .cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 80%;
      gap: 2rem;
      .card {
        width: 20rem;
        transform: scale(0.95);
        &:hover {
          transform: scale(1);
          filter: drop-shadow(0 0 0.2rem #072d50);
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  #Home {

    .namePresent {
      h1 {
        font-size: calc(8vw + 1rem) !important;
      }
    }

    .twitchPresent {
      flex-direction: column;
      padding: 2vh 0 2vh 0;
      min-height: 40rem;
      background-position: 50% 55%;

      .section {
        width: 80%;
        min-height: 40%;
      }
    }

    .partnersPresent {
      .cards {
        align-items: center;
        text-align: center;
      }
    }
  }
}