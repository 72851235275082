Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #07071a;
  color: #cccccc;

  min-height: 6.25rem;
}